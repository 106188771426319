import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { API } from '../httpclient/data';

export interface WalletState {
    // connected: boolean;
    connectedAddress?: string;
    userInfo?: API.UserDetail,
}

const initialState: WalletState = {
    // connected: false,
    connectedAddress: '',
};

export const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        setConnectedStatus: (state, action: PayloadAction<string>) => {
            // state.connected = true;
            state.connectedAddress = action.payload;
        },
        setDisconnectStatus: (state) => {
            // state.connected = false;
            state.connectedAddress = undefined;
        },
        setUserInfo: (state, action: PayloadAction<API.UserDetail>) => {
            state.userInfo = action.payload;
        },
    }
});

export const { setConnectedStatus, setDisconnectStatus, setUserInfo } = walletSlice.actions;

// export const selectConnected = (state: RootState) => state.wallet.connected;
export const selectConnectedAddress = (state: RootState) => state.wallet.connectedAddress;
export const selectUserInfo = (state: RootState) => state.wallet.userInfo;

export default walletSlice.reducer;