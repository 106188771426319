import React, { useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { SolanaProvider } from './components/SolanaProvider';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { extendTheme, ChakraProvider } from '@chakra-ui/react';
import Home from './pages/Home';
import Layout from './components/Layout';
import ErrorPage from './components/ErrorPage';
import reportWebVitals from './reportWebVitals';
import EarnPage from './pages/earn';
import TaskPage from './pages/task';
import ActivityPage from './pages/activity';
import ProfilePage from './pages/profile';
import DonationPage from './pages/donation';

import './index.css';
// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: '#0f0f0f',
        fontFamily: "'SHPinscher Regular'"
      }
    }
  },
  colors: {
    brand: {
      100: "#DA5431",
    },
    brandGray: {
      100: "#f2f4f5",
      200: "#eaedef",
    }
  },
})

// react-route-dom
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/earn',
        element: <EarnPage />
      },
      {
        path: '/task',
        element: <TaskPage />
      },
      {
        path: '/profile',
        element: <ProfilePage />
      },
      {
        path: '/activity',
        element: <ActivityPage />
      },
      {
        path: '/donation',
        element: <DonationPage />
      }
    ]
  },
]);

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <SolanaProvider>
          { /* Your app's components go here, nested within the context providers. */}
          <RouterProvider router={router} />
        </SolanaProvider>
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
