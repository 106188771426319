import './Home.css';
import Typist from 'react-typist-component';

function getQueryString(name: string) {
    const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    const r = window.location.search.substring(1).match(reg);  //获取url中"?"符后的字符串并正则匹配
    let context = "";
    if (r != null)
        context = r[2];

    return context == null || context == "" || context == "undefined" ? "" : context;
}

export default function Home() {
    const referralCode = getQueryString('referralCode');
    if (referralCode && localStorage.getItem('referralCode') === null) {
        localStorage.setItem('referralCode', referralCode);
    }

    return (
        <div className="home-body">
            <div className="home-poster">
                <img src="assets/ic_corner.png" alt="corner" className="home-poster-corner" />
                <div className="home-poster-brief">
                    <Typist typingDelay={80} cursor={<span className='cursor'>|</span>}>
                        Inspired by Mars exploration, a limited,&nbsp;
                        <Typist.Delay ms={1300} />
                        community-driven cryptocurrency.

                    </Typist>
                    <div className="home-poster-brief-short">
                        Complete a series of Mars exploration tasks to earn more token rewards.
                    </div>
                </div>
            </div>

            <div className="home-content">
                <div className="home-tokenomics">
                    <img src="assets/bg_tokenomics_poster.png" alt="tokenomics poster" />
                </div>
                <div className="home-roadmap">
                    <p className="home-roadmap-title">Roadmap</p>
                    <img src="assets/bg_roadmap.png" alt="poster3" />
                </div>
            </div>
        </div>
    );
}
