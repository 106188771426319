import { Link as ChakraLink,Container, Heading } from "@chakra-ui/react";
import { NavLink, useRouteError } from "react-router-dom";

import "./index.css";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Container maxW="container.xl" className="errorpage-container">
      <div className="errorpage-content box-shadow">
        <div>
          <Heading as='h1' size='xl'>404</Heading>
          <p>Not Found.</p>
          <p className="errorpage-gohome">
            Go back to the <ChakraLink as={NavLink} to="/">home page</ChakraLink>
          </p>
        </div>
      </div>
    </Container >
  );
}