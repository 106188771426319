import * as React from 'react';
import { Button, Center, Container, Modal, ModalBody, ModalContent, ModalOverlay, Spinner, useDisclosure, useToast } from '@chakra-ui/react';
import { useAppSelector } from '../../app/hooks';
import { selectConnectedAddress } from '../../features/wallet/walletSlice';
import { ApiClient } from '../../features/httpclient/ApiClient';

import "./index.css";

export default function TaskPage() {
    const toast = useToast();
    const connectedAddress = useAppSelector(selectConnectedAddress);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [gifLoading, setGifLoading] = React.useState<boolean>(true);
    const [gifSrc, setGifSrc] = React.useState<string>();
    const [tasks, setTasks] = React.useState<{ [key: string]: boolean }>({
        'LaunchTask': true,
        'RouteTask': true,
        'LandingTask': true
    });

    const getDailyTask = async () => {
        const _dailyasks = await ApiClient.getDailyTask('All');

        const _tasks: { [key: string]: boolean } = {};
        _dailyasks.forEach(v => {
            _tasks[v.taskName] = v.canExecute;
        });

        setTasks(_tasks);
    };

    const start = async (taskName: 'LaunchTask' | 'RouteTask' | 'LandingTask') => {
        if (connectedAddress) {
            setGifLoading(true);
            setGifSrc(`assets/task_${taskName}.gif`);
            onOpen();

            const result = await ApiClient.executeDailyTask(taskName);
            if (result.code === 'OK') {
                await getDailyTask();
            } else if (result.code === 'ERR_REPEAT_OPERATION') {
                onClose();
                toast({
                    description: "Completed today!",
                    status: 'warning',
                    duration: 3000,
                    isClosable: true,
                });
            } else if (result.code === 'ERR_CONDITIONS_NOT_SATISFIED') {
                onClose();
                toast({
                    description: "Please connect an address with at least 0.001 SOL balance!",
                    status: 'warning',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                onClose();
                toast({
                    description: result.code,
                    status: 'warning',
                    isClosable: true,
                });
            }
        } else {
            toast({
                description: "Please connect wallet.",
                status: 'warning',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const claim = () => {
        onClose();
        toast({
            description: "Claim successful!",
            status: 'success',
            isClosable: true,
        });
    };

    React.useEffect(() => {
        if (connectedAddress) {
            getDailyTask();
        }
    }, [connectedAddress]);

    return (<Container maxWidth="container.xl">
        <div className="task-header">
            <img className="task-header-icon" src="assets/ic_mission.png" alt="Mars exploration mission icon" />Mars exploration mission
        </div>
        <img src="assets/bg_mission.png" alt="Mars exploration mission" className="task-mission-post" />
        <div className="task-header">
            <img className="task-header-icon" src="assets/ic_dailytask.png" alt="Daily task icon" />Daily Task
        </div>
        <div className="task-item">
            <div className="task-item-post">
                <img src="assets/ic_launchtask.png" alt="launch task" />
            </div>
            <div className="task-item-description">
                <p className="task-item-description-title">Starship Launch Task</p>
                <p>Daily completion of one interstellar spacecraft launch task from Earth to Mars, to test SpaceX's capability in launching interstellar spacecraft. Each mission earns a reward of one energy gem.</p>
                <Button variant="link" className="task-item-btn" onClick={() => start('LaunchTask')} isDisabled={!tasks['LaunchTask']}>
                    <img src="assets/ic_arrow_right.png" alt="arrow" />{tasks['LaunchTask'] ? 'Start' : 'Completed'}
                </Button>
            </div>
        </div>
        <div className="task-item" id="fuck-marscoin">
            <div className="task-item-description">
                <p className="task-item-description-title">Earth-to-Mars Route Task</p>
                <p>Daily completion of one interstellar route task from Earth to Mars, gathering relevant data to maintain interstellar route stability. Each mission earns a reward of 2 energy gems.</p>
                <Button variant="link" className="task-item-btn" onClick={() => start('RouteTask')} isDisabled={!tasks['RouteTask']}>
                    <img src="assets/ic_arrow_right.png" alt="arrow" />{tasks['RouteTask'] ? 'Start' : 'Completed'}
                </Button>
            </div>
            <div className="task-item-post">
                <img src="assets/ic_routetask.png" alt="launch task" />
            </div>
        </div>
        <div className="task-item">
            <div className="task-item-post">
                <img src="assets/ic_landingtask.png" alt="launch task" />
            </div>
            <div className="task-item-description">
                <p className="task-item-description-title">Mars Landing Task</p>
                <p>Complete one daily Mars landing task, where you will pilot the Mars spacecraft to touch down on the Martian surface and commence base construction, preparing for future large-scale human settlement. Each mission awards 5 energy gems.</p>
                <Button variant="link" className="task-item-btn" onClick={() => start('LandingTask')} isDisabled={!tasks['LandingTask']}>
                    <img src="assets/ic_arrow_right.png" alt="arrow" />{tasks['LandingTask'] ? 'Start' : 'Completed'}
                </Button>
            </div>
        </div>

        <Modal onClose={onClose} isOpen={isOpen} isCentered size="xl" closeOnOverlayClick={false}>
            <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(12px)" />
            <ModalContent bgColor="transparent" boxShadow="unset">
                <ModalBody>
                    <img src={gifSrc} alt="gif" onLoad={() => setTimeout(() => setGifLoading(false), 5000)} />
                    {gifLoading
                        ? undefined
                        : <Center>
                            <Button
                                variant="link"
                                onClick={claim}
                                className="task-item-btn">
                                <img src="assets/ic_arrow_right.png" alt="arrow" />Claim
                            </Button>
                        </Center>}
                </ModalBody>
            </ModalContent>
        </Modal>
    </Container>)
};