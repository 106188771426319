import * as React from 'react';
import { Avatar, Button, Center, Container, Spinner, useToast } from '@chakra-ui/react';
import { useWallet } from '@solana/wallet-adapter-react';
import { ApiClient } from '../../features/httpclient/ApiClient';
import { API } from '../../features/httpclient/data';
import { useAppSelector } from '../../app/hooks';
import { selectConnectedAddress } from '../../features/wallet/walletSlice';

import "./index.css";

interface InvitedFriend {
    pageCursor: string;
    avatar: string;
    address: string;
    amount: number;
}

const PAGE_LIMIT = 25;

export default function ActivityPage() {
    const { connected } = useWallet();
    const toast = useToast();
    const connectedAddress = useAppSelector(selectConnectedAddress);

    const [invitationLink, setInvitationLink] = React.useState<string>('Please connect wallet!');
    const [hasMore, setHasMore] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [inviters, setInviters] = React.useState<InvitedFriend[]>([]);
    const [streakTask, setStreakTask] = React.useState<API.DailyTask[]>([]);
    // const [inviters, setInviters] = React.useState<InvitedFriend[]>(
    //     [
    //         { pageCursor: '1', avatar: 'assets/avatars/a1.png', address: 'Tsjf....89or', amount: 100 },
    //         { pageCursor: '2', avatar: 'assets/avatars/a2.png', address: 'Ak98....uir', amount: 150 },
    //     ]);

    const getReferralCode = async () => {
        const userInfo = await ApiClient.getUser();
        const streakTask = await ApiClient.getDailyTask('StreakTask');

        setStreakTask(streakTask);
        setInvitationLink(`https://marscoin.pro/?referralCode=${userInfo.referralCode}`);
    };

    const copyToClipboard = async () => {
        if (streakTask[0].continuous === 0) {
            toast({ description: 'You must complete a daily streak before inviting others!', status: 'warning' });
            return;
        }

        if (navigator.clipboard) {
            await navigator.clipboard.writeText(invitationLink);
            toast({ description: 'Copy successful!', status: 'success' });
        } else {
            toast({ description: 'Copy failed!', status: 'error' });
        }
    };

    const getInvitation = async () => {
        if (connectedAddress) {
            setLoading(true);

            const last = inviters.length > 0 ? inviters[inviters.length - 1].pageCursor : undefined;
            const data = await ApiClient.getInvitations(PAGE_LIMIT, last);
            if (data.length < PAGE_LIMIT) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }

            const append = data.map(v => ({ pageCursor: v.cursor, avatar: 'assets/avatars/a1.png', address: v.invitee, amount: v.bonus } as InvitedFriend))

            setInviters([...inviters, ...append]);
            setLoading(false);
        }
    };

    React.useEffect(() => {
        if (connectedAddress) {
            getReferralCode()
                .then(() => getInvitation());
        } else {
            setLoading(false);
        }
    }, [connectedAddress]);

    return (<Container maxWidth="container.xl">
        <div className="activity-poster">
            <div className="activity-poster-intro">
                <p className="activity-poster-intro-title"><img className="activity-poster-intro-icon" src="assets/ic_dollar.png" alt="dailystrak" />Invite more, earn more</p>
                In the cosmos of 69420, energy gems are vital resources for Mars exploration, highly prized. You can earn more energy gems by inviting new friends to join the Mars exploration program and participate in missions. The more people you invite, the more energy gems you'll receive.
            </div>
            <img src="assets/bg_activity.png" alt="background" />
        </div>
        <div className="activity-invitation">
            <div className="activity-invitation-title">Invitefriendsto receive a 10% referral bonus.</div>
            <p>By using the invitation link, once successfully invited, it will be linked to your address. Thereafter, when your invited friends complete missions and earn energy gem rewards, you will also receive a 10% energy gem reward.</p>
            <div className="activity-invitation-action">
                <span className="activity-invitation-action-title">My invitation link</span>
                <div className="activity-invitation-action-mobile-fix">
                    <div id="invitationLink" className="activity-invitation-action-link">{invitationLink}</div>
                    <Button variant="link" onClick={copyToClipboard} isDisabled={!connected} className="activity-invitation-action-link-copy"><img src="assets/ic_copy.png" alt="copy" /></Button>
                </div>
                <Button variant="link" className="activity-invitation-action-share" onClick={copyToClipboard} isDisabled={!connected}><img src="assets/ic_arrow_right.png" alt="arrow" />Share</Button>
            </div>
        </div>
        <div className="activity-invited">
            <div className="activity-invited-header">
                <p className="activity-invited-listitem-cell">Invited friends</p>
                <p className="activity-invited-listitem-cell">Earn Rewards</p>
            </div>
            <div className="activity-invited-list">
                {inviters.map(v =>
                    <div key={`invited-${v.address}`} className="activity-invited-listitem">
                        <div className="activity-invited-listitem-cell">
                            <Avatar className="activity-invited-listitem-avatar" src={v.avatar} />
                            <span>{v.address}</span>
                        </div>
                        <div className="activity-invited-listitem-cell">
                            <span className="activity-invited-listitem-reward">+{v.amount}</span><img src="assets/energygems.png" alt="energygems" />
                        </div>
                    </div>)}
                {inviters.length === 0 && loading === false ?
                    <Center key="activity-invited-list-no-data" color="#D2D2D2" fontSize="xl" marginTop={12} marginBottom={12}>
                        {connected ? 'No Inviters' : 'Please connect wallet!'}
                    </Center>
                    : undefined}

                {loading ?
                    <Center key="activity-invited-list-loading" color="#D2D2D2" fontSize="xl" marginTop={12} marginBottom={12}>
                        <Spinner size="lg" color="#B2B2B2" margin="4" />
                    </Center>
                    : undefined}

                {hasMore ?
                    <Center key="activity-invited-list-loadmore" fontSize="xl" marginTop={4} marginBottom={4}>
                        <Button variant="link" color="#D2D2D2" onClick={getInvitation}>Load more</Button>
                    </Center>
                    : undefined}
            </div>
        </div >
    </Container >)
};