import { FC, ReactNode, useMemo } from "react";
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';
import { CoinbaseWalletAdapter } from '@solana/wallet-adapter-coinbase';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';

export const SolanaProvider: FC<{ children: ReactNode }> = (props: { children: ReactNode }) => {

    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    //TDOD 部署主网后修改为Mainnet
    const network = WalletAdapterNetwork.Mainnet;
    const endpoint = 'https://solana-mainnet.g.alchemy.com/v2/3u1waWXo0SRZn439UPJAV-g75rE6Mjea';
    const wsEndpoint = 'wss://solana-mainnet.g.alchemy.com/v2/3u1waWXo0SRZn439UPJAV-g75rE6Mjea';

    // const network = WalletAdapterNetwork.Devnet;
    // const endpoint = 'https://solana-devnet.g.alchemy.com/v2/OglNysUQ05EB3RYZsjSvM2Ot7htVaxcM';
    // const wsEndpoint = 'wss://solana-devnet.g.alchemy.com/v2/OglNysUQ05EB3RYZsjSvM2Ot7htVaxcM';

    // You can also provide a custom RPC endpoint.
    // const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter({ network }),
            new SolflareWalletAdapter({ network }),
            new CoinbaseWalletAdapter({ network }),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [network]
    );

    return (
        <ConnectionProvider endpoint={endpoint} config={{ wsEndpoint: wsEndpoint }}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    {/* <WalletMultiButton />
                    <WalletDisconnectButton /> */}
                    { /* Your app's components go here, nested within the context providers. */}
                    {props.children}
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};