import { struct, u8 } from "@solana/buffer-layout";
import { u64 } from '@solana/buffer-layout-utils';
import { AccountMeta, PublicKey, Signer, TransactionInstruction } from "@solana/web3.js";

/** Address of the SPL Token program */
export const TOKEN_PROGRAM_ID = new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA');

export enum TokenInstruction {
    InitializeMint = 0,
    InitializeAccount = 1,
    InitializeMultisig = 2,
    Transfer = 3,
    Approve = 4,
    Revoke = 5,
    SetAuthority = 6,
    MintTo = 7,
    Burn = 8,
    CloseAccount = 9,
    FreezeAccount = 10,
    ThawAccount = 11,
    TransferChecked = 12,
    ApproveChecked = 13,
    MintToChecked = 14,
    BurnChecked = 15,
    InitializeAccount2 = 16,
    SyncNative = 17,
    InitializeAccount3 = 18,
    InitializeMultisig2 = 19,
    InitializeMint2 = 20,
    GetAccountDataSize = 21,
    InitializeImmutableOwner = 22,
    AmountToUiAmount = 23,
    UiAmountToAmount = 24,
    InitializeMintCloseAuthority = 25,
    TransferFeeExtension = 26,
    ConfidentialTransferExtension = 27,
    DefaultAccountStateExtension = 28,
    Reallocate = 29,
    MemoTransferExtension = 30,
    CreateNativeMint = 31,
    InitializeNonTransferableMint = 32,
    InterestBearingMintExtension = 33,
    CpiGuardExtension = 34,
    InitializePermanentDelegate = 35,
    TransferHookExtension = 36,
    // ConfidentialTransferFeeExtension = 37,
    // WithdrawalExcessLamports = 38,
    MetadataPointerExtension = 39,
    GroupPointerExtension = 40,
    GroupMemberPointerExtension = 41,
}

export function trimAddress(addr: string) {
    return addr.slice(0, 6) + '...' + addr.slice(addr.length - 4);
}

export function getLevel(num: number) {
    if (num === 0) {
        return 0;
    } else if (num <= 250) {
        return 1;
    } else if (num <= 380) {
        return 2;
    } else if (num <= 500) {
        return 3;
    } else if (num <= 650) {
        return 4;
    } else if (num <= 870) {
        return 5;
    } else if (num <= 1119) {
        return 6;
    } else if (num <= 1367) {
        return 7;
    } else if (num <= 1740) {
        return 8;
    } else {
        const increase = num - 1740;
        const remainMod = increase / 500;
        return parseInt((8 + remainMod).toFixed(0));
    }
}

export function getLevelPercent(num: number): number {
    if (num === 0) {
        return 1;
    } else if (num <= 250) {
        return (num / 251) * 100;
    } else if (num <= 380) {
        return (num / 381) * 100;
    } else if (num <= 500) {
        return (num / 501) * 100;
    } else if (num <= 650) {
        return (num / 651) * 100;
    } else if (num <= 870) {
        return (num / 871) * 100;
    } else if (num <= 1119) {
        return (num / 1120) * 100;
    } else if (num <= 1367) {
        return (num / 1368) * 100;
    } else if (num <= 1740) {
        return (num / 1741) * 100;
    } else {
        const level = getLevel(num) - 8;
        const points = (level + 1) * 500;
        return (num / (1741 + points)) * 100;
    }
}

export function getLevelRemain(num: number): number {
    if (num === 0) {
        return 250;
    } else if (num <= 250) {
        return 251 - num;
    } else if (num <= 380) {
        return 381 - num;
    } else if (num <= 500) {
        return 501 - num;
    } else if (num <= 650) {
        return 651 - num;
    } else if (num <= 870) {
        return 871 - num;
    } else if (num <= 1119) {
        return 1120 - num;
    } else if (num <= 1367) {
        return 1368 - num;
    } else if (num <= 1740) {
        return 1741 - num;
    } else {
        const level = getLevel(num) - 8;
        const points = (level + 1) * 500;
        return (1741 + points) - num;
    }
}

/** TODO: docs */
export interface TransferInstructionData {
    instruction: TokenInstruction.Transfer;
    amount: bigint;
}

/** TODO: docs */
export const transferInstructionData = struct<TransferInstructionData>([u8('instruction'), u64('amount')]);

/** @internal */
export function addSigners(
    keys: AccountMeta[],
    ownerOrAuthority: PublicKey,
    multiSigners: (Signer | PublicKey)[]
): AccountMeta[] {
    if (multiSigners.length) {
        keys.push({ pubkey: ownerOrAuthority, isSigner: false, isWritable: false });
        for (const signer of multiSigners) {
            keys.push({
                pubkey: signer instanceof PublicKey ? signer : signer.publicKey,
                isSigner: true,
                isWritable: false,
            });
        }
    } else {
        keys.push({ pubkey: ownerOrAuthority, isSigner: true, isWritable: false });
    }
    return keys;
}

/**
 * Construct a Transfer instruction
 *
 * @param source       Source account
 * @param destination  Destination account
 * @param owner        Owner of the source account
 * @param amount       Number of tokens to transfer
 * @param multiSigners Signing accounts if `owner` is a multisig
 * @param programId    SPL Token program account
 *
 * @return Instruction to add to a transaction
 */
export function createTransferInstruction(
    source: PublicKey,
    destination: PublicKey,
    owner: PublicKey,
    amount: number | bigint,
    multiSigners: (Signer | PublicKey)[] = [],
    programId = TOKEN_PROGRAM_ID
): TransactionInstruction {
    const keys = addSigners(
        [
            { pubkey: source, isSigner: false, isWritable: true },
            { pubkey: destination, isSigner: false, isWritable: true },
        ],
        owner,
        multiSigners
    );

    const data = Buffer.alloc(transferInstructionData.span);
    transferInstructionData.encode(
        {
            instruction: TokenInstruction.Transfer,
            amount: BigInt(amount),
        },
        data
    );

    return new TransactionInstruction({ keys, programId, data });
}
