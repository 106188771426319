// A mock function to mimic making an async request for data

export interface ApiData {
    temperatureC: number
}

export async function fetchCount(): Promise<ApiData[]> {
    const response = await fetch('weatherforecast');
    return await response.json() as ApiData[];
}
