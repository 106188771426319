import { Container } from '@chakra-ui/react';
import './index.css';

export default function Footer() {
    return (
        <>
            <footer className="footer">
                <img src="assets/logo.png" alt="logo" />
                <p className="footer-copyright">© 2024 MARSCOIN. All rights reserved.</p>
                <p className="footer-intro">
                    <a href="https://telegram.com"><img src="assets/social-tg.png" alt="Telegram" /></a>
                    <a href="https://twitter.com"><img src="assets/social-x.png" alt="X" /></a>
                </p>
            </footer>
            <footer className="footer-mobile">
                <div className="footer-mobile-about">
                    <img className="footer-mobile-brand" src="assets/logo.png" alt="logo" />
                    <p>
                        <a className="footer-mobile-social" href="https://telegram.com"><img src="assets/social-tg.png" alt="Telegram"/></a>
                        <a  className="footer-mobile-social" href="https://twitter.com"><img src="assets/social-x.png" alt="X"/></a>
                    </p>
                </div>
                <p className="footer-copyright">© 2024 MARSCOIN. All rights reserved.</p>
            </footer>
        </>
    );
}