import { API } from './data';

export namespace ApiClient {
    export function getUserAssets(): Promise<API.UserAssets> {
        return fetch(`/api/profile/assets`,
            {
                method: 'GET'
            })
            .then(res => res.json() as Promise<API.UserAssets>);
    }

    export function getSwapConfig(): Promise<API.SwapConfig> {
        return fetch(`/api/profile/swapconfig`,
            {
                method: 'GET'
            })
            .then(res => res.json() as Promise<API.SwapConfig>);
    }

    export function getRechargeConfig(): Promise<API.RechargeConfig> {
        return fetch(`/api/profile/rechargeconfig`,
            {
                method: 'GET'
            })
            .then(res => res.json() as Promise<API.RechargeConfig>);
    }

    export function getUserAssetsHistory(starOn: string, endOn: string): Promise<API.UserAssetsRecord[]> {
        return fetch(`/api/profile/assets/history?startOn=${starOn}&endOn=${endOn}`,
            {
                method: 'GET'
            })
            .then(res => res.json() as Promise<API.UserAssetsRecord[]>);
    }

    export function getUser(): Promise<API.UserDetail> {
        return fetch(`/api/profile`,
            {
                method: 'GET'
            })
            .then(res => res.json() as Promise<API.UserDetail>);
    }

    export function getDailyTask(taskName: 'All' | 'LaunchTask' | 'RouteTask' | 'LandingTask' | 'StreakTask'): Promise<API.DailyTask[]> {
        return fetch(`/api/dailytask?taskName=${taskName}`,
            {
                method: 'GET'
            })
            .then(res => res.json() as Promise<API.DailyTask[]>);
    }

    export function getDailyTaskConfig(): Promise<API.DailyTaskConfig> {
        return fetch(`/api/dailytask/config`,
            {
                method: 'GET'
            })
            .then(res => res.json() as Promise<API.DailyTaskConfig>);
    }

    export function executeDailyTask(taskName: 'All' | 'LaunchTask' | 'RouteTask' | 'LandingTask' | 'StreakTask', txSig?: string): Promise<API.ApiResultCode> {
        return fetch(`/api/dailytask/${taskName}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ txSignature: txSig })
            })
            .then(res => res.json() as Promise<API.ApiResultCode>);
    }

    export function getRank(rankType: 'Rank' | 'Rank_Referral'): Promise<API.Rank[]> {
        return fetch(`/api/rank/${rankType}`,
            {
                method: 'GET'
            })
            .then(res => res.json() as Promise<API.Rank[]>);
    }

    export function getCurrentRank(rankType: 'Rank' | 'Rank_Referral'): Promise<API.Rank> {
        return fetch(`/api/rank/current/${rankType}`,
            {
                method: 'GET'
            })
            .then(res => res.json() as Promise<API.Rank>);
    }

    export function modifyUser(form: API.ModifyUserProfileForm): Promise<API.ApiResultCode> {
        return fetch(`/api/profile`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(form)
            })
            .then(res => res.json() as Promise<API.ApiResultCode>);
    }

    export function swap(form: API.SwapForm): Promise<API.ApiResultCode> {
        return fetch(`/api/profile/swap`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(form)
            })
            .then(res => res.json() as Promise<API.ApiResultCode>);
    }

    export function recharge(form: API.RechargeForm): Promise<API.ApiResultCode> {
        return fetch(`/api/profile/recharge`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(form)
            })
            .then(res => res.json() as Promise<API.ApiResultCode>);
    }

    export function logout(): Promise<void> {
        return fetch(`/api/profile/logout`,
            {
                method: 'DELETE',
            })
            .then(res => console.log('sign out:', res.statusText));
    }

    export function getInvitations(limit: number, last?: string): Promise<API.Invitation[]> {
        return fetch(`/api/profile/invitation?limit=${limit}&last=${last ?? ''}`,
            {
                method: 'GET'
            })
            .then(res => res.json() as Promise<API.Invitation[]>);
    }

    export function getLoginRandomString(address: string): Promise<API.LoginRandomString> {
        return fetch(`/api/login/${address}`,
            {
                method: 'GET'
            })
            .then(res => res.json() as Promise<API.LoginRandomString>);
    }

    export function login(form: API.SignatureForm): Promise<API.ApiResultCode> {
        return fetch(`/api/login`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(form)
            })
            .then(res => res.json() as Promise<API.ApiResultCode>);
    }
}
