import React, { useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { Avatar, Button, Popover, PopoverBody, PopoverContent, PopoverTrigger, Progress, useToast } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { selectUserInfo, setConnectedStatus, setUserInfo } from "../../../features/wallet/walletSlice";
import { getLevel, getLevelPercent, getLevelRemain } from "../../../features/postcard/Utils";
import { ApiClient } from "../../../features/httpclient/ApiClient";

import './index.css';

const LOGIN_STORAGE_KEY = "auto";

export default function Header() {
    const [popupOpen, setPopupOpen] = useState<boolean>(false);

    const { publicKey, signMessage, disconnect, connect, connected } = useWallet();

    const toast = useToast();

    const userInfo = useAppSelector(selectUserInfo);

    const dispatch = useAppDispatch();

    //连接钱包
    const connectWallet = async () => {
        try {
            //获取当前钱包地址
            const connAddr = publicKey?.toBase58();
            if (!connAddr || !signMessage) {
                toast({
                    description: "Please install wallet!",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });

                return;
            }

            //根据钱包地址获取随机字符串
            const rndStr = await ApiClient.getLoginRandomString(connAddr)
            if (!rndStr) {
                return null;
            }

            //获取引荐人信息
            const inviter = localStorage.getItem('referralCode');

            //准备签名字符串
            const { address, timestamp, nonce, verification } = rndStr;
            const randomStr = `${address}${timestamp}${nonce}`;
            const messageBytes = new TextEncoder().encode(randomStr);

            //签名
            const signature = await signMessage(messageBytes);

            //使用签名进行登录
            const error = await ApiClient.login({
                verification: verification,
                signature: signature.reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), ''),
                inviter: inviter,
            });

            if (error && error.code === 'OK') {
                //获取当前登录用户信息
                const result = await ApiClient.getUser();

                localStorage.setItem(LOGIN_STORAGE_KEY, JSON.stringify([result.address]));

                if (inviter !== null) {
                    localStorage.removeItem('referralCode');
                }

                dispatch(setUserInfo(result));
                dispatch(setConnectedStatus(result.address));
            } else {
                toast({
                    description: 'Login failed',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
                await disconnect();
                console.log(error);
            }
        } catch (error) {
            toast({
                description: 'Connect failed',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            await disconnect();
            console.log(error);
        }
    };

    //断开连接
    const disconnectWallet = async () => {
        try {
            if (localStorage) {
                localStorage.removeItem(LOGIN_STORAGE_KEY);
                localStorage.removeItem("walletName");
            }

            await disconnect();
            await ApiClient.logout();

            window.location.href = "/";
        } catch (error) {
            console.log(error);
        }
    };

    const selectWallet = (d: 'pc' | 'mobile') => {
        const btnStyle: React.CSSProperties = d === 'pc' ? {
            backgroundImage: 'url(assets/bg_connect.png)',
            backgroundSize: '100% 100%',
            backgroundColor: 'unset',
            fontFamily: "'SHPinscher Regular'",
            color: '#B2B2B2',
            lineHeight: '2rem',
            minWidth: '170px',
            fontSize: '1.2rem',
            justifyContent: 'center'
        } : {
            backgroundImage: 'url(assets/bg_connect.png)',
            backgroundSize: '100% 100%',
            backgroundColor: 'unset',
            fontFamily: "'SHPinscher Regular'",
            color: '#B2B2B2',
            lineHeight: '2rem',
            height: '35px',
            minWidth: '100px',
            fontSize: '1rem',
            justifyContent: 'center'
        };

        if (connected && userInfo === undefined)
            return <Button onClick={connect} style={btnStyle}>Select Wallet</Button>;

        return <WalletMultiButton style={btnStyle} />;
    };

    useEffect(() => {
        if (!localStorage) return;

        //获取自动登录信息
        const json = localStorage.getItem(LOGIN_STORAGE_KEY);
        if (json === null) {
            //没有登录过，但是已经连接钱包了
            if (connected && publicKey) {
                connectWallet();
            }
        } else if (connected === false && publicKey == null) {
            //登录过，但是没有连接钱包
            connect().catch(() => disconnectWallet());
        } else if (connected && publicKey && !userInfo) {
            ApiClient.getUser()
                .then(userDetail => {
                    const autoLoginAddresses = JSON.parse(json) as string[];
                    if (autoLoginAddresses !== null && autoLoginAddresses.length > 0 && autoLoginAddresses[0] === publicKey.toBase58()) {
                        dispatch(setConnectedStatus(userDetail.address));
                        dispatch(setUserInfo(userDetail));
                    } else {
                        disconnectWallet();
                    }
                })
                .catch(() => {
                    disconnectWallet();
                });
        }
    }, [connected]);

    return (
        <div className="header">
            <header className="header-fixed">
                <nav className="nav">
                    <div className="nav-container">
                        <NavLink className="nav-brand" to="/"><img src="assets/logo.png" alt="logo" /></NavLink>

                        <NavLink className="nav-link" to="/">Home</NavLink>
                        <NavLink className="nav-link" to="/earn">Earn</NavLink>
                        <NavLink className="nav-link" to="/task">Task</NavLink>
                        <NavLink className="nav-link" to="/activity">Activity</NavLink>
                        <NavLink className="nav-link" to="/donation">Donation</NavLink>

                        {connected && userInfo ?
                            <Popover>
                                <PopoverTrigger>
                                    <Avatar cursor="pointer" bg="blue.500" name={userInfo.nickname} src={userInfo.photo} />
                                </PopoverTrigger>
                                <PopoverContent bg="#0F0F0F" border="#0F0F0F">
                                    <PopoverBody className="nav-profile">
                                        <div className="nav-profile-body">
                                            <Avatar size="lg" bg="blue.500" name={userInfo.nickname} src={userInfo.photo} />
                                            <div className="nav-profile-body-info">
                                                <p className="nav-profile-body-username">{userInfo.nickname}</p>
                                                <p className="nav-profile-body-level">Level {getLevel(userInfo.points)}</p>
                                            </div>
                                        </div>
                                        <Progress value={getLevelPercent(userInfo.points)} colorScheme="orange" borderRadius="md" mb={2} />
                                        <p>{getLevelRemain(userInfo.points)} Unit Next Level</p>
                                        <Button as={NavLink} to="/profile" className="nav-profile-btn" mt={3}>
                                            <img src="assets/ic_profile.png" alt="profile icon" />
                                            My Profile
                                        </Button>
                                        <Button className="nav-profile-btn" onClick={disconnectWallet} mt={2}>
                                            <img src="assets/ic_disconnect.png" alt="disconnect" />
                                            Disconnect
                                        </Button>
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                            :
                            selectWallet('pc')}
                    </div>
                </nav>
                <nav className="nav-mobile">
                    <div className="nav-container">
                        <NavLink className="nav-brand" to="/"><img src="assets/logo.png" alt="logo" /></NavLink>
                        <div>
                            {connected && userInfo ?
                                <Popover>
                                    <PopoverTrigger>
                                        <Avatar height="40px" width="40px" cursor="pointer" bg="blue.500" name={userInfo.nickname} src={userInfo.photo} />
                                    </PopoverTrigger>
                                    <PopoverContent bg="#0F0F0F" border="#0F0F0F">
                                        <PopoverBody className="nav-profile">
                                            <div className="nav-profile-body">
                                                <Avatar size="lg" bg="blue.500" name={userInfo.nickname} src={userInfo.photo} />
                                                <div className="nav-profile-body-info">
                                                    <p className="nav-profile-body-username">{userInfo.nickname}</p>
                                                    <p className="nav-profile-body-level">Level {getLevel(userInfo.points)}</p>
                                                </div>
                                            </div>
                                            <Progress value={getLevelPercent(userInfo.points)} colorScheme="orange" borderRadius="md" mb={2} />
                                            <p>{getLevelRemain(userInfo.points)} Unit Next Level</p>
                                            <Button as={NavLink} to="/profile" className="nav-profile-btn" mt={3}>
                                                <img src="assets/ic_profile.png" alt="profile icon" />
                                                My Profile
                                            </Button>
                                            <Button className="nav-profile-btn" onClick={disconnectWallet} mt={2}>
                                                <img src="assets/ic_disconnect.png" alt="disconnect" />
                                                Disconnect
                                            </Button>
                                        </PopoverBody>
                                    </PopoverContent>
                                </Popover>
                                :
                                selectWallet('mobile')}
                            <Button
                                backgroundColor="transparent"
                                onClick={() => setPopupOpen(!popupOpen)}
                                _hover={{ backgroundColor: '#1F1F1F' }}>
                                {popupOpen ? <img src="assets/ic_close.png" alt="close" /> : <img src="assets/ic_bars.png" alt="menu" />}
                            </Button>
                        </div>
                    </div>
                </nav>
            </header>
            {popupOpen ? <>
                <div className="popup-cover" onClick={() => setPopupOpen(false)} />
                <div className="popup-mobile">
                    <NavLink className="popup-mobile-link" to="/" onClick={() => setPopupOpen(false)}>Home</NavLink>
                    <NavLink className="popup-mobile-link" to="/earn" onClick={() => setPopupOpen(false)}>Earn</NavLink>
                    <NavLink className="popup-mobile-link" to="/task" onClick={() => setPopupOpen(false)}>Task</NavLink>
                    <NavLink className="popup-mobile-link" to="/activity" onClick={() => setPopupOpen(false)}>Activity</NavLink>
                    <NavLink className="popup-mobile-link" to="/donation" onClick={() => setPopupOpen(false)}>Donation</NavLink>
                </div>
            </> : undefined}
        </div>
    );
}