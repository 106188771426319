import { NavLink } from 'react-router-dom';
import * as React from 'react';
import {
    Connection,
    Transaction,
    TransactionInstruction,
    SystemProgram,
    PublicKey
} from '@solana/web3.js';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { Avatar, Box, Button, Center, Container, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr, Wrap, WrapItem, useDisclosure, useToast } from '@chakra-ui/react';
import { ApiClient } from '../../features/httpclient/ApiClient';
import { API } from '../../features/httpclient/data';
import { selectConnectedAddress, selectUserInfo } from '../../features/wallet/walletSlice';
import { getLevel } from '../../features/postcard/Utils';
import { useAppSelector } from '../../app/hooks';

import "./index.css";

interface StreakState {
    index: number;
    locked: boolean;
    reward: number;
}

interface RankState {
    rank: number;
    badge?: string;
    avatar: string;
    nickname: string;
    level: number;
    reward: number;
}

const TASK_ACCOUNT_SEED = 'STREAK_TASK';
const DAILYTASK_SIZE = 8;

async function createAccount(
    publicKey: PublicKey,
    newAccount: PublicKey,
    programId: PublicKey,
    connection: Connection): Promise<TransactionInstruction> {
    console.log('Creating account', newAccount.toBase58());

    const lamports = await connection.getMinimumBalanceForRentExemption(
        DAILYTASK_SIZE,
    );

    return SystemProgram.createAccountWithSeed({
        fromPubkey: publicKey,
        basePubkey: publicKey,
        seed: TASK_ACCOUNT_SEED,
        newAccountPubkey: newAccount,
        lamports,
        space: DAILYTASK_SIZE,
        programId: programId,
    });

    // const transaction = new Transaction().add(
    //     SystemProgram.createAccountWithSeed({
    //         fromPubkey: publicKey,
    //         basePubkey: publicKey,
    //         seed: TASK_ACCOUNT_SEED,
    //         newAccountPubkey: newAccount,
    //         lamports,
    //         space: DAILYTASK_SIZE,
    //         programId: programId,
    //     }),
    // );
    // await sendTransaction(transaction, connection);

    // const {
    //     context: { slot: minContextSlot },
    //     value: { blockhash, lastValidBlockHeight },
    // } = await connection.getLatestBlockhashAndContext();

    // try {
    //     const signature = await sendTransaction(transaction, connection, {
    //         minContextSlot,
    //         skipPreflight: true,
    //         signers: [],
    //         preflightCommitment: 'processed',
    //     });
    //     console.log('Creating account', { blockhash, lastValidBlockHeight, signature, minContextSlot });

    //     const confirmtx = await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature });
    //     console.log('Creating account', { signature, confirmtx });
    // } catch (e) {
    //     console.log('Creating account', e);
    // }
}

function BoardItem(props: { currentRank: RankState }) {
    return <div className="earn-leaderboard-listitem">
        <div className="earn-leaderboard-profile">
            {props.currentRank.badge ? <img src={props.currentRank.badge} alt="badge" className="earn-leaderboard-badge" /> : <Text className="earn-leaderboard-nottop" fontSize="xl">{props.currentRank.rank > 20 || props.currentRank.rank === 0 ? '20+' : props.currentRank.rank.toString()}</Text>}
            <Avatar src={props.currentRank.avatar} className="earn-leaderboard-avatar" />
            <div className="earn-leaderboard-userinfo">
                <Text>{props.currentRank.nickname}</Text>
                <div className="earn-leaderboard-level">Level {props.currentRank.level}</div>
            </div>
        </div>
        <div className="earn-leaderboard-reward">
            <img src="assets/energygems.png" alt="energygems" width="20px" />
            <Text ml={2}>{props.currentRank.reward}</Text>
        </div>
    </div>
}

export default function EarnPage() {
    //States
    const nowTime = new Date();
    const [startOn, setStartOn] = React.useState<string>(nowTime.toISOString().substring(0, 10) + ' 00:00:00');
    const [endOn, setEndOn] = React.useState<string>(nowTime.toISOString().substring(0, 10) + ' 23:59:59');

    const [histories, setHistories] = React.useState<API.UserAssetsRecord[]>([]);
    const [ranks, setRanks] = React.useState<RankState[]>([]);
    const [ranksLoading, setRanksLoading] = React.useState<boolean>(true);
    const [currentRank, setCurrentRank] = React.useState<RankState>();
    const [userAssets, setUserAssets] = React.useState<API.UserAssets>();
    const [rankTab, setRankTab] = React.useState<'Rank' | 'Rank_Referral'>('Rank');
    const [loading, setLoading] = React.useState<boolean>(false);
    const [dailyStreakConfig, setDailyStreakConfig] = React.useState<API.DailyTaskConfig>();
    const [streakTask, setStreakTask] = React.useState<API.DailyTask>({
        taskName: 'StreakTask',
        continuous: 0,
        canExecute: true,
        lastExecuteOn: new Date().toISOString()

    });
    const [streak, setStreak] = React.useState<StreakState[]>([
        { index: 1, locked: true, reward: 2 },
        { index: 2, locked: true, reward: 4 },
        { index: 3, locked: true, reward: 6 },
        { index: 4, locked: true, reward: 8 },
        { index: 5, locked: true, reward: 10 },
        { index: 6, locked: true, reward: 12 },
        { index: 7, locked: true, reward: 15 },
    ]);

    //Hooks
    const toast = useToast();
    const connectedAddress = useAppSelector(selectConnectedAddress);
    const userInfo = useAppSelector(selectUserInfo);

    const { publicKey, sendTransaction, connected } = useWallet();
    const { connection } = useConnection();
    const { isOpen, onOpen, onClose } = useDisclosure();

    //funaction
    const getConfig = async () => {
        const _config = await ApiClient.getDailyTaskConfig();

        setDailyStreakConfig(_config);
    };

    const getUserAssets = async () => {
        const _userAssets = await ApiClient.getUserAssets();

        setUserAssets(_userAssets);
    };

    const getUserAssetsHistory = async () => {
        const _histories = await ApiClient.getUserAssetsHistory(startOn, endOn);

        setHistories(_histories);
    };

    const getRank = async (rankType: 'Rank' | 'Rank_Referral') => {
        const _rank = await ApiClient.getRank(rankType);

        setRanksLoading(false);
        setRanks(
            _rank.map((v, i) => ({
                rank: v.ranking,
                badge: v.ranking <= 3 && v.ranking !== 0 ? `assets/ic_top${v.ranking}.png` : undefined,
                avatar: v.photo,
                nickname: v.nickname,
                level: getLevel(v.points),
                reward: rankType === 'Rank' ? v.totalReward : v.totalBonus
            } as RankState))
        );
    };

    const getCurrentRank = async (rankType: 'Rank' | 'Rank_Referral') => {
        const _currentRanking = await ApiClient.getCurrentRank(rankType);

        setCurrentRank({
            rank: _currentRanking.ranking,
            badge: _currentRanking.ranking <= 3 && _currentRanking.ranking !== 0 ? `assets/ic_top${_currentRanking.ranking}.png` : undefined,
            avatar: _currentRanking.photo,
            nickname: _currentRanking.nickname,
            level: getLevel(_currentRanking.points),
            reward: rankType === 'Rank' ? _currentRanking.totalReward : _currentRanking.totalBonus
        } as RankState);
    };

    const getDailyTask = async () => {
        const _streakTask = await ApiClient.getDailyTask('StreakTask');
        const today = new Date().getTime();
        const last = new Date(_streakTask[0].lastExecuteOn).getTime();

        //是否超过48小时，超过48小时则代币断签，天数直接为0
        const _streakDays = (today - last) > 172800000 ? 0 : _streakTask[0].continuous;

        const streaks: StreakState[] = [];
        for (let index = 1; index <= 7; index++) {
            streaks.push({ index: index, locked: !(_streakDays >= index), reward: index === 7 ? 15 : index * 2 })
        }

        setStreak(streaks);
        setStreakTask(_streakTask[0]);
    };

    const changeTab = async (rankType: 'Rank' | 'Rank_Referral') => {
        setRankTab(rankType);

        await getRank(rankType);
        await getCurrentRank(rankType);
    }

    // const test = async () => {
    //     await ApiClient.executeDailyTask('StreakTask', '5u8Bw1e1sQTQ1V6BRww6UT8w3Djs6cs8gGiHDbtsSWX3gUy6gtMxDZNmCP4h5Zyxb4mGnpnvcqjR4Xnhxio57aCq');
    // };

    const collect = async () => {
        if (connected && publicKey && dailyStreakConfig) {

            let signature: string | null = null;
            let blockContext: { context: { slot: number }, value: { blockhash: string, lastValidBlockHeight: number } } | null = null;

            //创建交易
            try {
                setLoading(true);

                const STREAK_TASK_PROGRAM_ID = new PublicKey(dailyStreakConfig.streakTaskProblemId);

                //创建一个钱包
                const taskAccountPubkey = await PublicKey.createWithSeed(
                    publicKey,
                    TASK_ACCOUNT_SEED,
                    STREAK_TASK_PROGRAM_ID,
                );

                const transaction = new Transaction();

                const taskAccount = await connection.getAccountInfo(taskAccountPubkey);
                if (taskAccount === null) {
                    //不存在，创建一个
                    const createAccountInstruction = await createAccount(publicKey, taskAccountPubkey, STREAK_TASK_PROGRAM_ID, connection);

                    transaction.add(createAccountInstruction);
                }

                transaction.add(
                    new TransactionInstruction({
                        keys: [
                            {
                                pubkey: taskAccountPubkey,
                                isSigner: false,
                                isWritable: true,
                            },
                            {
                                pubkey: SystemProgram.programId,
                                isSigner: false,
                                isWritable: false,
                            },
                        ],
                        programId: STREAK_TASK_PROGRAM_ID,
                    })
                );

                if (dailyStreakConfig.streakTaskFee > 0) {
                    transaction.add(
                        SystemProgram.transfer({
                            fromPubkey: publicKey,
                            toPubkey: new PublicKey(dailyStreakConfig.streakTaskFeeAccount),
                            lamports: dailyStreakConfig.streakTaskFee,
                        })
                    );
                }

                blockContext = await connection.getLatestBlockhashAndContext();
                const {
                    context: { slot: minContextSlot },
                    value: { blockhash, lastValidBlockHeight },
                } = blockContext;

                signature = await sendTransaction(transaction, connection, {
                    minContextSlot,
                    skipPreflight: true,
                    signers: [],
                    preflightCommitment: 'processed',
                });
                console.log('execute task - sendTransaction', { blockhash, lastValidBlockHeight, signature, minContextSlot });
            } catch (error) {
                console.log(error);

                toast({
                    description: "Send Transaction failed!",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });

                setLoading(false);
                return;
            }

            //等待交易确认
            try {
                if (blockContext === null || signature === null) throw new Error('Transaction send failed!');

                const {
                    context: { slot: minContextSlot },
                    value: { blockhash, lastValidBlockHeight },
                } = blockContext;

                const confirmtx = await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature });
                console.log('execute task - confirmTransaction', { signature, confirmtx });
            } catch (error) {
                console.error('execute task - confirmTransaction', error);
            }

            if (signature === null) {
                toast({
                    description: "Transaction send failed!",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });

                setLoading(false);
                return;
            }

            //提交交易签名
            const result = await ApiClient.executeDailyTask('StreakTask', signature);
            if (result.code === 'ERR_REPEAT_OPERATION') {
                toast({
                    description: "Can only be collected once within 24 hours!",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else if (result.code === 'OK') {
                toast({
                    description: "Collect successful!",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });

                await getDailyTask();
                await getUserAssets();
                await getUserAssetsHistory();
            } else {
                toast({
                    description: "Collect failed!",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }

            setLoading(false);
        } else {
            toast({
                description: "Please connect wallet.",
                status: 'warning',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    React.useEffect(() => {
        if (connectedAddress) {
            getUserAssets()
                .then(() => getDailyTask())
                .then(() => getConfig())
                .then(() => getCurrentRank('Rank'))
                .then(() => getRank('Rank'))
                .then(() => getUserAssetsHistory());
        } else {
            getRank('Rank');
        }
    }, [connectedAddress]);

    return (<div className="earn-body">
        {connected && publicKey ?
            <div className="earn-profile">
                <Avatar className="earn-profile-avatar" src={userInfo ? userInfo.photo : 'assets/avatars/a1.png'} />
                <Text color="white" fontSize="2xl" mt={4}>{userInfo ? userInfo.nickname : publicKey.toBase58()}</Text>
                <div className="earn-profile-reward">
                    <div className="earn-profile-reward-item">
                        <Text>DAILY REWARD</Text>
                        <Text className="earn-profile-reward-data">{userAssets ? userAssets.dailyReward : '···'}</Text>
                    </div>
                    <div className="earn-profile-reward-item">
                        <Text>TOTAL REWARD</Text>
                        <Text className="earn-profile-reward-data">{userAssets ? userAssets.totalReward : '···'}</Text>
                    </div>
                </div>
            </div> : undefined}
        <Container maxWidth="container.xl">
            <div className="earn-dailystreak">
                <div className="earn-dailystreak-header">
                    <div className="earn-dailystreak-title-containter">
                        <p className="earn-dailystreak-title">
                            <img className="earn-dailystreak-icon" src="assets/ic_dailystreak.png" alt="dailystrak" />Daily Streak
                            <Tooltip fontSize="lg" size="lg" label={
                                <ul style={{ listStyleType: 'none' }}>
                                    <li>· Collected once every 24 hours.</li>
                                    <li>· Miss a day, and your streak resets to Day 1.</li>
                                </ul>}>
                                <img className="earn-dailystreak-tooltip" src="assets/ic_info.png" alt="info" />
                            </Tooltip>
                        </p>
                        {/* <div className="earn-dailystreak-tooltip">
                            <img src="assets/ic_info.png" alt="info" />
                            <ul>
                                <li>Collected once every 24 hours.</li>
                                <li>Miss a day, and your streak resets to Day 1.</li>
                            </ul>
                        </div> */}
                        <p className="earn-dailystreak-berif">Sign in for a week straight, watch your prizes escalate</p>
                    </div>
                    <div className="earn-dailystreak-action">
                        <Button variant="link" className="earn-dailystreak-collect" isDisabled={!streakTask.canExecute} isLoading={loading} onClick={collect}>
                            <img src="assets/ic_arrow_right.png" alt="arrow" />
                            {streakTask.canExecute ? 'Claim' : 'Claimed'}
                        </Button>
                        <Button variant="link" className="earn-dailystreak-history" onClick={onOpen} isDisabled={!connected}>History→</Button>
                    </div>
                </div>
                <Wrap spacing="1.5rem" className="earn-dailystreak-list">
                    {streak.map(v =>
                        <WrapItem flex={1} key={`dailystreak-${v.index}`}>
                            <Box className={`earn-dailystreak-box ${v.locked ? '' : 'earn-dailystreak-box-unlock'}`}>
                                <Box className={`earn-dailystreak-box-energy ${v.locked ? 'earn-dailystreak-box-energy-locked' : ''}`}>
                                    <img src="assets/ic_stone.png" alt="stone" />
                                    <p>x{v.reward}</p>
                                </Box>
                                <p>DAY {v.index}</p>
                            </Box>
                        </WrapItem>)}
                </Wrap>
            </div>

            <div className="earn-dailytask">
                <p className="earn-dailytask-title"><img className="earn-dailytask-icon" src="assets/ic_dailytask.png" alt="dailystrak" />Daily Task</p>
                <Wrap spacing={12} justify='center' className="earn-dailytask-list">
                    <WrapItem className="earn-dailytask-item">
                        <Box as={NavLink} to="/task" className="earn-dailytask-box">
                            <img src="assets/ic_launchtask.png" alt="launch task" />
                        </Box>
                        Starship Launch Task
                        <Text fontSize="2xl" verticalAlign="middle" mt={4}><img src="assets/energygems.png" alt="energygems" className="earn-dailytask-box-energy" />x 1</Text>
                    </WrapItem>
                    <WrapItem className="earn-dailytask-item">
                        <Box as={NavLink} to="/task" className="earn-dailytask-box">
                            <img src="assets/ic_routetask.png" alt="route task" />
                        </Box>
                        Earth-to-Mars Route Task
                        <Text fontSize="2xl" verticalAlign="middle" mt={4}><img src="assets/energygems.png" alt="energygems" className="earn-dailytask-box-energy" />x 2</Text>
                    </WrapItem>
                    <WrapItem className="earn-dailytask-item">
                        <Box as={NavLink} to="/task" className="earn-dailytask-box">
                            <img src="assets/ic_landingtask.png" alt="landing task" />
                        </Box>
                        Mars Landing Task
                        <Text fontSize="2xl" verticalAlign="middle" mt={4}><img src="assets/energygems.png" alt="energygems" className="earn-dailytask-box-energy" />x 5</Text>
                    </WrapItem>
                </Wrap>
            </div>

            <div className="earn-leaderboard">
                <div className="earn-leaderboard-header">
                    <p className="earn-leaderboard-title">
                        <img className="earn-leaderboard-icon" src="assets/ic_board.png" alt="dailystrak" />Leader Board
                    </p>
                    <p className="earn-leaderboard-tips-mobile">Updated every 1 hours</p>

                    <div className="earn-leaderboard-tabs">
                        <Button variant="link" className={`earn-leaderboard-tabitem ${rankTab === 'Rank' ? 'active' : ''}`} onClick={() => changeTab('Rank')}>
                            <img src="assets/ic_arrow_right.png" alt="arrow" className={`earn-leaderboard-tabitem-icon ${rankTab === 'Rank' ? 'active' : ''}`} /> Total Reward
                        </Button>
                        <Button variant="link" className={`earn-leaderboard-tabitem ${rankTab === 'Rank_Referral' ? 'active' : ''}`} onClick={() => changeTab('Rank_Referral')}>
                            <img src="assets/ic_arrow_right.png" alt="arrow" className={`earn-leaderboard-tabitem-icon ${rankTab === 'Rank_Referral' ? 'active' : ''}`} />Referral Bonus
                        </Button>
                    </div>
                </div>
                <p className="earn-leaderboard-tips-pc">Updated every 1 hours</p>

                <div className="earn-leaderboard-list">
                    {currentRank ? <>
                        <Text className="earn-leaderboard-class">Your ranking</Text>
                        <BoardItem currentRank={currentRank} />
                    </> : undefined}

                    <Text className="earn-leaderboard-class">Top</Text>
                    {ranks.map(v => <BoardItem currentRank={v} key={`rank-${v.rank}`} />)}

                    {ranksLoading ? <Center><Spinner size="lg" color="#B2B2B2" margin="4" /></Center> : undefined}
                </div>
            </div>
        </Container>
        <Modal onClose={onClose} isOpen={isOpen} size="5xl" isCentered>
            <ModalOverlay />
            <ModalContent className="earn-history-modal-content">
                <ModalCloseButton color="#B2B2B2" size="lg" />
                <ModalBody className="earn-history-modal">
                    <div className="earn-history">
                        <div className="earn-history-header">
                            <Text color="#D2D2D2" fontSize="4xl"><img width="20" src="assets/ic_arrow_right.png" alt="history" className="earn-history-header-icon" />&nbsp;History</Text>
                        </div>
                        <div className="earn-history-filter">
                            <input title="Start Data" name="starton" type="date" onChange={(e) => setStartOn(e.target.value)} onBlur={getUserAssetsHistory} />
                            <Text>—</Text>
                            <input title="End Date" name="endon" type="date" onChange={(e) => setEndOn(e.target.value)} onBlur={getUserAssetsHistory} />
                            <Tooltip label="The historical datas are based on UTC">
                                <img src="assets/ic_info.png" alt="info" />
                            </Tooltip>
                        </div>
                    </div>
                    <TableContainer className="earn-history-table">
                        <Table variant='simple'>
                            <Thead>
                                <Tr>
                                    <Th>Task completed</Th>
                                    <Th>Date</Th>
                                    <Th>Reward</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {histories.map((v, i) =>
                                    <Tr key={`earn-history-${i}`}>
                                        <Td>{v.name}</Td>
                                        <Td>{v.dateKey}</Td>
                                        <Td>{v.reward}</Td>
                                    </Tr>)}
                            </Tbody>
                        </Table>
                    </TableContainer>
                    {histories.length === 0 ? <Box margin={8}><Center color="#D2D2D2" fontSize="xl">No historical records available</Center></Box> : undefined}
                </ModalBody>
            </ModalContent>
        </Modal>
    </div>
    )
};