import * as React from 'react';
import { Button, Container, useToast } from '@chakra-ui/react';

import "./index.css";
const DONATION_ADDRESS = "6TVfkjyDa9cYjqEHuPN14eUnUjqg4avxcQbnYMjamV8r";

export default function DonationPage() {
    const toast = useToast();

    const copyToClipboard = async () => {
        if (navigator.clipboard) {
            await navigator.clipboard.writeText(DONATION_ADDRESS);
            toast({ description: 'Copy successful!', status: 'success' });
        } else {
            toast({ description: 'Copy failed!', status: 'error' });
        }
    };

    return (<Container maxWidth="container.xl">
        <div className="donation-poster">
            <div className="donation-poster-intro">
                <p className="donation-poster-intro-title">
                    <img className="donation-poster-intro-icon" src="assets/ic_donation.png" alt="donation program" />
                    <span>Donation program</span>
                </p>
                To support the sustainable development of the project, the Donation Program is part of the $Marscoin community's social experiment aimed at aiding in brand building and project operations, as well as amplifying community influence. All donated funds will be used transparently, and donors will receive corresponding $Marscoin airdrop rewards. We encourage more members of the community to join in the construction efforts, making us one of the strongest communities out there.
            </div>
            <img src="assets/bg_donation.png" alt="background" />
        </div>
        <div className="donation-receiving">
            <div className="donation-receiving-title">Donation receiving address (Solana only)</div>
            <div className="donation-receiving-action">
                <span className="donation-receiving-action-title">SOL</span>
                <div id="invitationLink" className="donation-receiving-action-link">{DONATION_ADDRESS}</div>
                <Button variant="link" className="donation-receiving-action-share" onClick={copyToClipboard}><img src="assets/ic_arrow_right.png" alt="arrow" />Copy</Button>
            </div>
        </div>
        <div className="donation-note">
            <div className="donation-note-item">
                <h3>The main purpose of the donated funds is:</h3>
                <p>The donated funds are primarily used for community development, airdrop rewards, and related expenses. </p>
                <p>The funds are allocated towards enhancing brand influence, covering expenses such as media and KOL payments.</p>
                <p>The funds are designated for the development and operation of future $Marscoin metaverse projects.</p>
            </div>
            <div className="donation-note-item">
                <h3>Noted!</h3>
                <p>Donated funds are solely intended to support the smooth operation of the project and are not to be construed as investment advice, nor do they guarantee any investment returns. Therefore, it's crucial for you to understand the donation terms clearly. We sincerely appreciate your support and understanding.</p>
            </div>
        </div >
    </Container >)
};