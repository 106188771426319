import * as React from 'react';
import { PublicKey, Transaction } from '@solana/web3.js';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Avatar, Box, Button, Container, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { ApiClient } from '../../features/httpclient/ApiClient';
import { API } from '../../features/httpclient/data';
import { selectUserInfo, setUserInfo } from '../../features/wallet/walletSlice';
import { useAppDispatch } from '../../app/hooks';
import { createTransferInstruction, getLevel, trimAddress } from "../../features/postcard/Utils";
import { selectConnectedAddress } from '../../features/wallet/walletSlice';
import { useAppSelector } from '../../app/hooks';

import './index.css';

const TOKEN_PROGRAM_ID = new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA');
// const TOKEN_2022_PROGRAM_ID = new PublicKey('TokenzQdBNbLqP5VEhdkAS6EPFLC1PHnBqCXEpPxuEb');
const ASSOCIATED_TOKEN_PROGRAM_ID = new PublicKey('ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL');

export default function ProfilePage() {
    //状态
    const [userAssets, setUserAssets] = React.useState<API.UserAssets>();
    const [swapConfig, setSwapConfig] = React.useState<API.SwapConfig>();
    const [rechargeConfig, setRechargeConfig] = React.useState<API.RechargeConfig>();
    const [tokenAccountBalance, setTokenAccountBalance] = React.useState<number>(0);

    //Hooks
    const dispatch = useAppDispatch();
    const connectedAddress = useAppSelector(selectConnectedAddress);
    const userInfo = useAppSelector(selectUserInfo);
    const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();

    //edit profile
    const toast = useToast();
    const [submitting, setSubmitting] = React.useState(false);
    const [swapOpen, setSwapOpen] = React.useState(false);
    const [rechargeOpen, setRechargeOpen] = React.useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    //编辑资料
    const initialEditRef = React.useRef(null)
    const finalEditRef = React.useRef(null)

    //swap
    const finalSwapRef = React.useRef(null);

    //recharge
    const finalRechargeRef = React.useRef(null);

    const getUserInfo = async () => {
        const userInfo = await ApiClient.getUser();

        if (connectedAddress === userInfo.address) {
            dispatch(setUserInfo(userInfo));
        }
    };

    const getRechargeConfig = async () => {
        if (publicKey) {
            const _rechargeConfig = await ApiClient.getRechargeConfig();

            const tokenAccount = PublicKey.findProgramAddressSync(
                [
                    publicKey.toBuffer(),
                    TOKEN_PROGRAM_ID.toBuffer(),
                    new PublicKey(_rechargeConfig.rechargeTokenMintAccount).toBuffer(),
                ],
                ASSOCIATED_TOKEN_PROGRAM_ID
            )[0];

            try {
                const info = await connection.getTokenAccountBalance(tokenAccount);
                if (info.value.uiAmount !== null) {
                    setTokenAccountBalance(info.value.uiAmount);
                }
            } catch (error) {
                console.log('current connected account', publicKey.toString(), 'no token account', _rechargeConfig.rechargeTokenMintAccount);
            }

            setRechargeConfig(_rechargeConfig);
        }
    };

    const getUserAssets = async () => {
        const _userAssets = await ApiClient.getUserAssets();

        setUserAssets(_userAssets);
    };

    const getSwapConfig = async () => {
        const _swapConfig = await ApiClient.getSwapConfig();

        setSwapConfig(_swapConfig);
    };

    const copyToClipboard = async () => {
        if (navigator.clipboard && userInfo) {
            await navigator.clipboard.writeText(userInfo.address);
            toast({ description: 'Copy successful!', status: 'success' });
        } else {
            toast({ description: 'Copy failed!', status: 'error' });
        }
    };

    const submitEditProfile = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!userInfo) return;

        setSubmitting(true);

        const form = e.currentTarget;
        const formData = new FormData(form);
        const nickname = formData.get('nickname');

        if (nickname === null || nickname.toString().length < 2) {
            setSubmitting(false);
            toast({
                description: "Please enter a nickname of at least 2 characters.",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            const result = await ApiClient.modifyUser({
                nickname: nickname,
                avatar: userInfo.photo,
                title: null,
                biography: null
            } as API.ModifyUserProfileForm);

            if (result.code === 'OK') {
                toast({
                    description: "Successfully modify",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });

                onClose();
                await getUserInfo();
            } else {
                toast({
                    description: result.code,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                description: 'Modify failed',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            console.log(error);
        } finally {
            setSubmitting(false);
        }
    };

    const submitSwap = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!userInfo) return;

        setSubmitting(true);

        const form = e.currentTarget;
        const formData = new FormData(form);
        const amount = formData.get('amount');

        if (amount === null || amount.toString().length === 0) {
            setSubmitting(false);
            toast({
                description: "Please enter amount.",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            const result = await ApiClient.swap({
                amount: parseFloat(amount.toString())
            } as API.SwapForm);

            if (result.code === 'OK') {
                toast({
                    description: "Successfully swap",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });

                setSwapOpen(false);
                await getUserAssets();
            } else if (result.code === 'ERR_INSUFFICIENT_BALANCE') {
                toast({
                    description: 'Insufficient balance!',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                toast({
                    description: result.code,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                description: 'Swap failed',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            console.log(error);
        } finally {
            setSubmitting(false);
        }
    };

    const submitRecharge = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!publicKey) return;
        if (!rechargeConfig) return;

        setSubmitting(true);

        const form = e.currentTarget;
        const formData = new FormData(form);
        const amount = formData.get('amount');

        if (amount === null || amount.toString().length === 0) {
            setSubmitting(false);
            toast({
                description: "Please enter amount.",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const TOKEN_MINT_ACCOUNT = new PublicKey(rechargeConfig.rechargeTokenMintAccount);
        const RECEIVE_ACCOUNT = new PublicKey(rechargeConfig.rechargeBeneficiaryAccount);

        const sourceAccount = PublicKey.findProgramAddressSync(
            [
                publicKey.toBuffer(),
                TOKEN_PROGRAM_ID.toBuffer(),
                TOKEN_MINT_ACCOUNT.toBuffer(),
            ],
            ASSOCIATED_TOKEN_PROGRAM_ID
        )[0];

        const destinationAccount = PublicKey.findProgramAddressSync(
            [
                RECEIVE_ACCOUNT.toBuffer(),
                TOKEN_PROGRAM_ID.toBuffer(),
                TOKEN_MINT_ACCOUNT.toBuffer(),
            ],
            ASSOCIATED_TOKEN_PROGRAM_ID
        )[0];

        let signature: string | null = null;
        let blockContext: { context: { slot: number }, value: { blockhash: string, lastValidBlockHeight: number } } | null = null;

        try {
            const transaction = new Transaction().add(
                createTransferInstruction(
                    sourceAccount,
                    destinationAccount,
                    publicKey,
                    parseFloat(amount.toString()) * Math.pow(10, 6),
                    [],
                    TOKEN_PROGRAM_ID
                )
            );

            blockContext = await connection.getLatestBlockhashAndContext();
            const {
                context: { slot: minContextSlot },
                value: { blockhash, lastValidBlockHeight },
            } = blockContext;

            signature = await sendTransaction(transaction, connection, {
                minContextSlot,
                skipPreflight: true,
                signers: [],
                preflightCommitment: 'processed',
            });

            console.log('token transfer - sendTransaction', { blockhash, lastValidBlockHeight, signature, minContextSlot });
        } catch (error) {
            console.log(error);

            toast({
                description: 'Send Transaction failed!',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            setSubmitting(false);
            return;
        }

        //等待交易确认
        try {
            if (blockContext === null || signature === null) throw new Error('Transaction send failed!');

            const {
                context: { slot: minContextSlot },
                value: { blockhash, lastValidBlockHeight },
            } = blockContext;

            const confirmtx = await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature });
            console.log('token transfer - confirmTransaction', { signature, confirmtx });
        } catch (error) {
            console.error('token transfer - confirmTransaction', error);
        }

        if (signature === null) {
            toast({
                description: "Transaction send failed!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            });

            setSubmitting(false);
            return;
        }

        //提交交易签名
        const result = await ApiClient.recharge({ txSignature: signature });
        if (result.code === 'OK') {
            toast({
                description: 'Recharge success!',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            setRechargeOpen(false);
            await getUserAssets();
        } else {
            toast({
                description: "Recharge failed!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }

        setSubmitting(false);
    };

    React.useEffect(() => {
        if (userInfo) {
            getUserAssets()
                .then(() => getSwapConfig())
                .then(() => getRechargeConfig());
        }
    }, [userInfo]);

    return (<Container maxWidth="container.xl">
        <div className="profile-container">
            <div className="profile-detail">
                <p><Avatar src={userInfo ? userInfo.photo : 'assets/avatars/a1.png'} className="profile-detail-avatar" /></p>
                <Text className="profile-detail-level">Level {getLevel(userInfo ? userInfo.points : 0)}</Text>
                <Text color="#B2B2B2" fontSize="2xl" mt={8}>{userInfo ? trimAddress(userInfo.address) : '--'} <Button colorScheme="transparent" onClick={copyToClipboard}><img width="20" src="assets/ic_copy.png" alt="copy" /></Button></Text>
                {
                    userInfo && (userInfo.rechargeEnabled || userInfo.swapEnabled) ?
                        <div className="profile-detail-action">
                            {userInfo.rechargeEnabled ? <Button variant="link" className="profile-detail-action-btn" onClick={() => setRechargeOpen(true)}>
                                <img src="assets/ic_arrow_right.png" alt="arrow" />Recharge
                            </Button> : undefined}
                            {userInfo.swapEnabled ? <Button variant="link" className="profile-detail-action-btn" onClick={() => setSwapOpen(true)}>
                                <img src="assets/ic_arrow_right.png" alt="arrow" />Swap
                            </Button> : undefined}
                        </div> : undefined
                }
            </div>
            <div className="profile-achievement">
                <Text className="profile-detail-username">{userInfo ? userInfo.nickname : 'Loading...'} <Button colorScheme="orange" variant='link' onClick={onOpen}>Edit</Button></Text>
                <Text fontSize="3xl" color="#B2B2B2">Achievement</Text>
                <div className="profile-achievement-body">
                    <div className="profile-achievement-body-total">
                        <span className="profile-achievement-body-total-title">
                            <img src="assets/ic_holder.png" alt="holder" />
                            Total energy gems
                        </span>
                        <span className="profile-achievement-body-total-value">{userAssets ? userAssets.totalReward : '0'}</span>
                        <img src="assets/energygems.png" alt="energygems" />
                    </div>
                    <div className="profile-achievement-body-invitation">
                        <img src="assets/ic_lines.png" alt="lines" />
                        <span className="profile-achievement-body-invitation-value">
                            <span>Invitation rewards</span>
                            <span className="profile-achievement-body-invitation-energygems">{userAssets ? userAssets.totalBonus : '0'}</span>
                        </span>
                        <img src="assets/energygems.png" alt="energygems" />
                    </div>
                </div>
            </div>
        </div>
        <Modal
            initialFocusRef={initialEditRef}
            finalFocusRef={finalEditRef}
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
            isCentered
        >
            <ModalOverlay />
            <ModalContent as="form" bg="#1F1F1F" onSubmit={submitEditProfile}>
                <ModalHeader color="#B2B2B2">Edit your profile</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl textAlign="center">
                        <Avatar cursor="pointer" size="xl" bg="orange.500" name={userInfo ? userInfo.photo : 'assets/avatars/a1.png'} src={userInfo ? userInfo.photo : ''} />
                    </FormControl>

                    <FormControl mt={4}>
                        <FormLabel color="White">Nickname</FormLabel>
                        <Input color="#B2B2B2" name="nickname" title="nickname" ref={initialEditRef} placeholder='Nickname' maxLength={30} defaultValue={userInfo ? userInfo.nickname : ''} />
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button type="submit" isLoading={submitting} colorScheme='orange' mr={3}>
                        Save
                    </Button>
                    <Button onClick={onClose} colorScheme="blackAlpha">Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>

        <Modal
            finalFocusRef={finalSwapRef}
            isOpen={swapOpen}
            onClose={() => setSwapOpen(false)}
            closeOnOverlayClick={false}
            isCentered
        >
            <ModalOverlay />
            <ModalContent as="form" bg="#1F1F1F" onSubmit={submitSwap}>
                <ModalHeader color="#B2B2B2">Swap</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl>
                        <FormLabel color="White">Available</FormLabel>
                        <Text color="#DA5431" lineHeight="1rem">{userAssets?.balance} <img src="assets/energygems.png" alt="energygems" className="profile-energygems" /></Text>
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel color="White">Amount</FormLabel>
                        <Input color="#B2B2B2" name="amount" title="Amount" placeholder='Amount (Energy Gems)' type="number" min={0} step="0.0001"/>
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel color="White">Current Rate</FormLabel>
                        <Text color="#B2B2B2" fontSize="xl" lineHeight="1rem">{swapConfig?.swapRate} <img src="assets/energygems.png" alt="energygems" className="profile-energygems" /> = 1 <img src="assets/usdt-img.png" alt="usdt" className="profile-energygems" /></Text>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button type="submit" isLoading={submitting} colorScheme='orange' mr={3}>
                        Swap
                    </Button>
                    <Button onClick={() => setSwapOpen(false)} colorScheme="blackAlpha">Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>

        <Modal
            finalFocusRef={finalRechargeRef}
            isOpen={rechargeOpen}
            onClose={() => setRechargeOpen(false)}
            closeOnOverlayClick={false}
            isCentered
        >
            <ModalOverlay />
            <ModalContent as="form" bg="#1F1F1F" onSubmit={submitRecharge}>
                <ModalHeader color="#B2B2B2">Recharge</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl>
                        <FormLabel color="White">USDT Balance</FormLabel>
                        <Text color="#DA5431" lineHeight="1rem">{tokenAccountBalance} <img src="assets/usdt-img.png" alt="usdt" className="profile-energygems" /></Text>
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel color="White">Amount</FormLabel>
                        <Input color="#B2B2B2" name="amount" title="Amount" placeholder='Amount (USDT)' type="number" min={0} step="0.0001"/>
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel color="White">Current Rate</FormLabel>
                        <Text color="#B2B2B2" fontSize="xl" lineHeight="1rem">1 <img src="assets/usdt-img.png" alt="usdt" className="profile-energygems" /> = {swapConfig?.rechargeRate} <img src="assets/energygems.png" alt="energygems" className="profile-energygems" /></Text>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button type="submit" isLoading={submitting} colorScheme='orange' mr={3}>
                        Recharge
                    </Button>
                    <Button onClick={() => setRechargeOpen(false)} colorScheme="blackAlpha">Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </Container >);
}